






















































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import StatementComponent from "./Statement.component";

@Component
export default class Statement extends Mixins(StatementComponent) {}
