import { Component, Vue } from "vue-property-decorator";
import { GET_STATEMENT } from "../../../../api/payment"
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';

Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class Statement extends Vue {
    $axios: any;
    private statementData: any = []
    private model: Model;
    private cookieData: any = Vue.$cookies.get("login")
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }
    private username = ""

    private filterUserId = ""
    private userId = ""
    private currency = {}

    private paginate = {
        page: 1,
        limit: 10
    }
    private pageSize = 0;
    private paginateShow = false;
    private dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    handlePick(date) {
        this.dateFilter.endDate = moment(date).local().endOf('day').toDate()
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = true
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.username = this.cookieData.mainUsername
        } else {
            this.username = this.cookieData.username
        }
        await this.getStatementDataById(this.username, this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async selectDate(option: string) {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getStatementDataById(this.username, this.paginate.page, this.paginate.limit)
    }

    public async clickCallback(number: number) {
        this.paginate.page = number
        await this.getStatementDataById(this.username, this.paginate.page, this.paginate.limit)
    }

    public async openModal(id: string, field: string, data?: any) {
        if (field == 'currency') {
            this.userId = id
            this.currency = {}
            if (typeof data != 'undefined') {
                data.products.forEach(val => {
                    Object.keys(val.rate).forEach(key => {
                        this.currency[key] = val.rate[key];
                    });
                });

                this.currency = Object.keys(this.currency).reduce((r, a) => {
                    r[a] = [...r[a] || [], a];
                    return r;
                }, {});
            } else {
                this.currency = { THB: [] }
            }
            this.$modal.show('modalCurrency');
        }
    }

    public async filterStatementData() {
        this.paginate.page = 1
        await this.getStatementDataById(this.username, this.paginate.page, this.paginate.limit)
    }

    private async getStatementDataById(user: string, page: number, limit: number) {
        this.statementData = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const lists = await GET_STATEMENT(user, this.filterUserId, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), page, limit)
        this.statementData = lists.data[0];

        if (this.statementData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.statementData.metadata.length; i++, k++) {
                this.statementData.metadata[i].no = k - this.paginate.limit
            }
            if (this.statementData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.statementData.pageInfo[0].size;
        } else {
            this.statementData = []
            this.paginateShow = false
        }
        loader.hide()
    }

    public hide(field: string) {
        if (field == 'currency') {
            this.$modal.hide('modalCurrency');
        }
    }
}

export default Statement